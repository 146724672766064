<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-column align-start' v-if="data">
          <div class="d-flex flex-row align-center">
            <v-btn text @click="()=>{$router.go(-1)}">
              <v-icon dark>mdi-arrow-left</v-icon>
            </v-btn>
            <h1>{{singular}} #{{ data.id }}</h1>
            <v-chip class="ml-4" :color="utils.getStocktakingEventStatusColor(data.status)">{{utils.parseStocktakingEventStatus(data.status)}}</v-chip>
            <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
            ></v-progress-circular>
            <v-btn class="ml-2" color="info" @click="update">Save Changes</v-btn>
            <v-btn class="ml-2" color="info" @click="startEvent">Start Event</v-btn>
            <v-btn class="ml-2" color="info" @click="endEvent">End Event</v-btn>
          </div>
          <h5>Created at: {{ utils.formatDate(data.createdAt) }} {{ utils.formatTime(data.createdAt) }}</h5>
          <h5 v-if="data.startedAt">Started at: {{ utils.formatDate(data.startedAt) }} {{ utils.formatTime(data.startedAt) }}</h5>
          <h5 v-if="data.endedAt">Ended at: {{ utils.formatDate(data.endedAt) }} {{ utils.formatTime(data.endedAt) }}</h5>
          <span class="d-flex flex-row">
            <v-checkbox
              v-if="data.metadata"
              v-model="data.metadata.strict"
              label="Strict Mode"
            />
            <v-checkbox
              class="ml-2"
              v-if="data.metadata"
              v-model="data.metadata.showNote"
              label="Display Note to Stocktakers"
            />
            <v-checkbox
              class="ml-2"
              v-if="data.metadata"
              v-model="data.metadata.showExpectedQuantities"
              label="Display Expected Quantities to Stocktakers"
            />
          </span>
          <div style="width: 100%;">
            <h2>Notes</h2>
            <v-textarea auto-grow rows="5" width="100%" outlined v-model="data.notes"></v-textarea>
          </div>
          <div v-if="data.status == 2">
            <h2>Reports</h2>
            <!-- <dynamicButtonContainer @res="getPrintResponse" v-if="getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_STOCKTAKING_EVENT_DIALOG')" :containerId="`${getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_DISPATCH_DIALOG')}`" :data="{disabled: !canPrint, id1: serialDialog.data.id, status: serialDialog.data.deliveryInfo.status}"/> -->
          </div>
          <div style="width: 70%;">
            <h2>Product Counts</h2>
            <span class="d-flex flex-column">
              <v-card outlined>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>PL</th>
                          <th>Product Name</th>
                          <th>Reported Count</th>
                          <th>Expected Count</th>
                          <th>Difference</th>
                          <th>Counted By</th>
                          <th>Counted At</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(count, index) in data.ProductCounts" :key="index">
                          <td>{{ index+1 }}</td>
                          <td>{{count.productId}}</td>
                          <td v-if="count.Product">{{ count.Product.name }}</td>
                          <td v-else>Reload page to get product info.</td>
                          <td>{{utils.pff(count.count)}}</td>
                          <td>{{totalSystemQuantities(count.pointInTimeSystemQuantities)}}</td>
                          <td>{{utils.pff(utils.pff(count.count)-(totalSystemQuantities(count.pointInTimeSystemQuantities)))}}</td>
                          <!-- <td v-else>Reload page to get product info.</td> -->
                          <td>{{ lookupUsername(count.createdBy) }}</td>
                          <td>{{ utils.formatDate(count.createdAt, 'withTime') }}</td>
                        </tr>  
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  // import dynamicButtonContainer from "../../components/dynamicButtonContainer.vue";
  import utils from "../../plugins/helpers"
// import lookup from 'socket.io-client';
  export default {
    components: {
      // dynamicButtonContainer
    },
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        data: [],
        singular: "Stocktaking Event",
        singularLower: "stocktakingEvent",
        plural: "Stocktaking Events",
        pluralLower: "stocktakingEvents",
        isEditable: false,
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error
          
          this.data = res.data.data

          console.log(this.data)

        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'lookupUsername'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async getPrintResponse(){
        console.log("Print Response")
      },
      totalSystemQuantities(arr){
        return utils.pff(arr.reduce((acc,x)=>acc+parseInt(x.quantity),0))
      },
      async update(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async startEvent(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/startEvent/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error
          console.log(res.data)
          this.data.status = res.data.status

          this.snack(`${this.singular} Event Started.`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async endEvent(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/endEvent/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error
          this.data.status = res.data.status

          this.snack(`${this.singular} Event Ended.`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async deleteData(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack(this.singular+" Deleted 🎉", "success");
          await this.$router.push({ path: `/${this.pluralLower}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      }
    }
  }
</script>
